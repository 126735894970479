@media only screen and (max-width: 900px) {
	.main__wrapper {
		padding: 0;
	}

	.main-header {
		padding: 45px $margin-mobile 30px $margin-mobile;
	}

	.two-columns {
		padding: 0;
		flex-direction: column;
		margin-top: 100px;
		margin-bottom: 0;

		h2,
		p {
			text-align: center;
		}

		&__column {
			@include flex-center-center;
			flex-direction: column;
			width: 100%;

			&--left {
				margin-bottom: 60px;
			}

			&--right img {
				left: 0;
			}
		}
	}

	.counters {
		margin-bottom: 60px;

		h2,
		p {
			text-align: center;
		}

		&__wrapper {
			flex-direction: column;
			row-gap: 50px;
		}

		&__container {
			width: 100%;

			&:nth-child(-n+3) {
				&::after {
					display: none;
				}
			}
		}

		.counter-info {
			margin-top: 10px;
		}
	}

	.footer {
		&__wrapper {
			padding: 20px 0;
			flex-direction: column;
			row-gap: 10px;

			>div {
				@include flex-center-center;
				width: 100%;
			}
		}

		&__social {
			justify-content: center !important;
		}
	}
}


@media only screen and (min-width: 620px) and (max-width: 900px) {

	.main__wrapper {
		padding: 0 $margin-tablet;
	}

	.main-header {
		padding: 45px $margin-tablet 30px $margin-tablet;
	}

	.two-columns {

		h2,
		p {
			text-align: center;
		}
	}

	.counters {

		&__wrapper {
			flex-direction: row;
			flex-wrap: wrap;
		}

		&__container {
			width: 50%;

			&:nth-child(-n+3) {
				&::after {
					display: none;
				}
			}

		}

		.counter-info {
			margin-top: 10px;
		}
	}
}

@media only screen and (min-width: 901px) and (max-width: 1100px) {
	.main__wrapper {
		padding: 0 $margin-tablet;
	}

	.main-header {
		padding: 45px $margin-tablet 30px $margin-tablet;
	}

	.two-columns {
		padding: 80px;
	}

	.counters {
		&__container {
			&:nth-child(-n+3) {
				&::after {
					display: none;
				}
			}

		}
	}
}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {
	// .main__wrapper {
	// 	padding: 0 $margin-desktop;
	// }

	.main-header {
		// padding: 45px $margin-desktop 30px $margin-desktop;
	}

	.two-columns {
		padding: $margin-desktop;
	}
}

@media only screen and (min-width: 1801px) {
	// .main__wrapper {
	// 	padding: 0 $margin-wide;
	// }

	.main-header {
		padding: 0 $margin-desktop;
	}

	// .two-columns {
	// 	padding: $margin-wide;
	// }
}
