@import './mixins.scss';

.navbar {
	&__links {
		&--wrapper {
			>li {
				&:hover~.navbar__submenu {
					display: block;
				}
				a.active {
					color: $secondary-color;
					border-bottom: 2px solid $secondary-color;
				}
			}
			a {
				@include MS-Medium-20-30;
				display: flex;
				align-items: center;
				text-transform: capitalize;
				background-color: transparent;
				color: $white;
				height: inherit;
				width: fit-content;

				&:hover {
					color: $secondary-color;

					svg {
						path {

							stroke: $secondary-color;
						}
					}
					~.navbar__submenu {
						height: 240px;
						border-top: 3px solid rgba(90, 90, 90, 0.5);
					}
				}
			}

		}
	}
}
