@import './mixins.scss';

.partners {
    // @include flex-center-center;
    // height: calc(100vh - ($header-height-retracted));

    .partners-gallery {
        // margin-bottom: calc($header-height-retracted/2);

        // h2 {
        //     @include MS-Bold-45-55;
        //     margin-bottom: 20px;
        //     text-align: right;
        // }

        // p {
        //     @include MS-Medium-18-24;
        //     text-align: right;
        // }

        // &__wrapper {
        //     @include flex-center-center;
        //     flex-wrap: nowrap;
        //     position: relative;
        //     gap: 20px;
        // }

        &__collumn {
            // width: 50%;

            // &--left {
            //     margin: 0 120px;
            // }

            &--right {
                @include flex-start-start;
                gap: 20px;
                position: relative;
                flex-wrap: wrap;
            }
        }

        &__tile {
            width: calc(20% - 15px);
            aspect-ratio: 1 / 1;
            padding: 10px;

            picture {
                img {
                    width: 100%;
                }
            }
        }
    }
}
