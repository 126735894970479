@import './assets/css/mixins.scss';
@import './assets/css/fonts.scss';

@import './assets/css/index.scss';

* {
	&::-webkit-scrollbar {
		width: 14px;
	}

	&::-webkit-scrollbar-track {
		background-color: white;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background-color: #898989;
		border-radius: 8px;
	}
}

.main__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: visible;
	padding: 0;
	z-index: 1;
}

.page:not(.contact-us) {
	transition: 0.4s;
	padding-left: 0;
	padding-top: calc($header-height + 80px);
	z-index: 1;
}

.main-button {
	@include flex-center-center;
	@include MS-Medium-18-24;
	text-transform: uppercase;
	padding: 12px 30px;
	background-color: $secondary-color;
	border-radius: 8px;
	color: $white;
	border: 2px solid $secondary-color;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		border: 2px solid $white;
		background-color: $complementary-color;
	}

	&.slide-down {
		background-color: rgba(0, 0, 0, 0.1);

		&:hover {
			background-color: rgba(0, 0, 0, 0.3);
		}
	}
}

.highlight {
	@include MS-Bold-16-20;
	color: $secondary-color;
}

.scroll-top {
	position: fixed;
	bottom: 10px;
	right: 20px;
	width: 60px;
	height: 60px;
	border: 4px solid $secondary-color;
	border-radius: 50%;
	z-index: 100;
	cursor: pointer;
	transition: 0.4s;


	svg {
		font-size: 30px;
		color: $secondary-color;
		transition: 0.4s;
	}

	&:hover {
		background-color: rgba($complementary-color, 0.2);

		svg {
			font-size: 36px;
		}
	}
}

section,
.section {
	padding-left: $margin-desktop;
	padding-right: $margin-desktop;

	&.full-width {
		padding-left: 0;
		padding-right: 0;
	}
}
