@import './mixins.scss';

.carousel {
	margin-bottom: 100px;
	// padding-top: 100px;
	width: 100%;

	h2 {
		@include MS-Bold-35-43;
		margin-bottom: 80px;
		text-align: center;
	}

	.slick-track {
		@include flex-center-center;

		.slick-slide {
			@include flex-center-center;

			&>div {
				@include flex-center-center;
			}
		}

		.single-slide {
			position: relative;
			@include flex-center-center;
			padding: 0 20px;
			align-items: stretch;

			&.bmw img {
				width: 150px;
			}

			img {
				width: 100%;
				margin: 0 !important;

			}
		}
	}
}


// .two-columns {
// 	// @include flex-center-center;
// 	// padding-top: 200px;
// 	// margin: 0 0 100px 0;

// 	&__column {
// 		&--left {
// 			// width: 60%;
// 			// padding-left: 200px;

// 			// h2 {
// 			// 	@include MS-Bold-35-43;
// 			// 	margin-bottom: 40px;
// 			// }

// 			// p {
// 			// 	@include MS-Medium-16-20;
// 			// 	padding-right: 10px;
// 			// 	margin-bottom: 33px;
// 			// }
// 		}

// 		&--right {
// 			width: 40%;

// 			img {
// 				position: relative;
// 				left: -100px;
// 			}
// 		}
// 	}
// }

.counters {
	@include flex-center-center;
	flex-direction: column;
	margin-bottom: 100px;
	padding-top: 100px;

	h2 {
		@include MS-Bold-35-43;
		margin-bottom: 80px;
		text-align: center
	}

	&__wrapper {
		@include flex-center-center;
		width: 100%;
	}

	&__container {
		@include flex-center-center;
		width: 25%;
		flex-direction: column;
		position: relative;

		&:nth-child(-n+3) {
			&::after {
				content: '';
				background-color: $light-gray;
				width: 1px;
				height: 80%;
				position: absolute;
				top: -20px;
				right: 0;
				transform: rotate(25deg)
			}
		}

		p.counter {
			@include MS-Bold-65-55;
			margin: 0 4px;
		}

		p.counter-info {
			@include MS-Light-20-24;
			margin-top: 20px;
			text-align: center;
		}

		.string {
			@include MS-Bold-45-55;
			display: inline-block;

			&--red {
				color: $secondary-color;
			}

			&--black {
				color: $black;
			}
		}
	}
}

.testimonials {
	position: relative;
	margin-bottom: 200px;

	h2 {
		@include MS-Bold-35-43;
		text-align: center;
		margin-bottom: 80px;
	}

	h4 {
		@include MS-Bold-20-30;
		margin-bottom: 20px;
	}

	p {
		@include MS-Medium-16-24;
		margin: 30px 0;
		color: rgb(90, 90, 90);
		font-style: italic;
	}

	svg {
		position: absolute;
		top: 20px;
		left: 40px;
		font-size: 30px;
		color: $secondary-color;
	}

	&__wrapper {
		@include flex-center-center;
		flex-direction: column;
		padding: 0 20px;
	}

	&__content {
		@include flex-center-center;
		flex-direction: column;
		padding: 40px 60px;
		border: 1px solid lightgray;
		border-radius: 8px;
		position: relative;

		img.svg {
			position: absolute;
			bottom: 0;
			z-index: 1;
		}
	}

	&__avatar {
		@include flex-center-center;
		flex-direction: column;
		position: relative;
		z-index: 2;

		h5 {
			@include MS-Bold-16-20;
		}

		p {
			@include MS-Medium-16-20;
		}

		picture {
			margin-bottom: 15px;

			img {
				width: 100px;
				aspect-ratio: 1 / 1;
				object-fit: cover;
				border-radius: 50%;
			}
		}

		&--info {
			@include flex-center-center;
			flex-direction: column;
			height: 100%;

			p {
				margin: 0;
			}
		}
	}

	.slick-arrow::before {
		color: $secondary-color;
		font-size: 25px;
	}

	.slick-dots {
		bottom: -60px;

		button::before,
		.slick-active button::before {
			color: $secondary-color;
			font-size: 10px;
		}


	}
}

@keyframes float {
	0% {
		top: 0;
	}
	50% {
		top: 15px;
	}
	100% {
		top: 0;
	}
}
