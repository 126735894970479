.page-title {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;

	&__wrapper {
		position: relative;
		height: 300px;
		overflow: hidden;
	}

	figure {
		height: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
