@font-face {
	font-family: "Montserrat-Bold";
	src: local("MontserratBold"),
		url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: local("MontserratRegular"),
		url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Light";
	src: local("MontserratLight"),
		url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Medium";
	src: local("MontserratMedium"),
		url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Signature";
	src: local("Signature"),
		url("../fonts/Creattion/Creattion.otf") format("opentype");
}
