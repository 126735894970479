@import './assets/css/mixins.scss';
@import './assets/css/responsive.scss';

*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	// border: 1px solid red;
}

// html {
// 	position: relative;
// }

html,
body,
#root {
	height: 100%;
	scroll-behavior: smooth;
}

body {
	position: relative;
	min-height: 100vh;
	background-color: $background-color;
}

#root {
	margin: 0 auto;
}

.App {
	width: 100%;
	overflow: hidden;
}

a {
	text-decoration: none;
	color: $white;
	transition: $transition;
}

button {
	border: none;
	background-color: transparent;
}

ul {
	list-style-type: none;
}

.contact-button {
	@include flex-center-center;
	width: 160px;
	height: 35px;
	background-color: $secondary-color;
	color: $white;
	border-radius: 15px;

	svg {
		margin-right: 10px;
	}

	&:hover {
		color: $black;

		svg path {
			stroke: $black;
		}
	}

	svg path {
		stroke: $white;
		// transition: $transition;
	}
}
