@import './mixins.scss';

.contact-banner {
	@include flex-center-center;
	flex-direction: column;
	padding: 40px;
	background: linear-gradient(135deg, rgba(80, 7, 7, 1) 0%, rgba(50, 35, 34, 1) 100%);

	h3 {
		@include MS-Bold-45-55;
		color: $white;
		margin-bottom: 30px
	}

	p {
		@include MS-Medium-18-24;
		color: $white;
		text-align: center;
		margin-bottom: 30px;
		padding: 0 20%;
	}
}
