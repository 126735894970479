/*  variables */

$white: #ffffff;
$black: #25242B;
$footer-color: #25242B;
$secondary-color: #5e42dd;
$complementary-color: #402962;
$light-gray: #BFBCBC;
$background-color: #F2F2F2;

$margin-mobile: 10px;
$margin-tablet: 20px;
$margin-desktop: 120px;
$margin-wide: 240px;


$header-height: 150px;
$header-height-retracted: 80px;
$transition: 0.4s;

/* layout */

@mixin flex-center-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@mixin flex-center-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin flex-center-start {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

@mixin flex-start-center {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

@mixin flex-end-center {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
}

@mixin flex-start-start {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

@mixin flex-start-between {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

/*  fonts */

@mixin MS-Light-20-24 {
	font-family: "Montserrat-Light";
	font-size: 20px;
	line-height: 24px;
}

@mixin MS-Light-14-18 {
	font-family: "Montserrat-Light";
	font-size: 14px;
	line-height: 18px;
}

@mixin MS-Medium-26-30 {
	font-family: "Montserrat-Regular";
	font-size: 26px;
	line-height: 30px;
}

@mixin MS-Medium-24-30 {
	font-family: "Montserrat-Regular";
	font-size: 24px;
	line-height: 30px;
}

@mixin MS-Medium-22-30 {
	font-family: "Montserrat-Regular";
	font-size: 22px;
	line-height: 30px;
}

@mixin MS-Medium-20-30 {
	font-family: "Montserrat-Regular";
	font-size: 20px;
	line-height: 30px;
}

@mixin MS-Medium-18-24 {
	font-family: "Montserrat-Medium";
	font-size: 18px;
	line-height: 24px;
}

@mixin MS-Medium-16-24 {
	font-family: "Montserrat-Medium";
	font-size: 16px;
	line-height: 24px;
}

@mixin MS-Medium-16-20 {
	font-family: "Montserrat-Medium";
	font-size: 16px;
	line-height: 20px;
}

@mixin MS-Medium-14-16 {
	font-family: "Montserrat-Medium";
	font-size: 14px;
	line-height: 16px;
}


@mixin MS-Bold-70-80 {
	font-family: "Montserrat-Bold";
	font-size: 70px;
	line-height: 80px;
}

@mixin MS-Bold-65-55 {
	font-family: "Montserrat-Bold";
	font-size: 65px;
	line-height: 55px;
}

@mixin MS-Bold-45-55 {
	font-family: "Montserrat-Bold";
	font-size: 45px;
	line-height: 55px;
}

@mixin MS-Bold-35-43 {
	font-family: "Montserrat-Bold";
	font-size: 35px;
	line-height: 43px;
}

@mixin MS-Bold-22-30 {
	font-family: "Montserrat-Bold";
	font-size: 22px;
	line-height: 30px;
}

@mixin MS-Bold-20-30 {
	font-family: "Montserrat-Bold";
	font-size: 20px;
	line-height: 30px;
}

@mixin MS-Bold-16-20 {
	font-family: "Montserrat-Bold";
	font-size: 16px;
	line-height: 20px;
}

@mixin Signature-16-20 {
	font-family: "Signature";
	font-size: 16px;
	line-height: 20px;
}
